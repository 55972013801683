
import Vue from 'vue';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';

export default Vue.extend({
  name: 'ExportMenu',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    pdfArchiveStatus: {
      type: Object,
      default: () => ({ done: 0, total: 0, running: false }),
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasExportToExcelLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_EXCEL);
    },
    menuItems() {
      const menuList = [
        {
          id: 'pdf',
          name: this.$t('exportMenu.exportToPdf'),
          disabled: this.pdfArchiveStatus.running,
          disabledHint: this.$t('exportMenu.disabledHintPdf'),
          icon: 'fa fal fa-file-pdf',
          isVisible: true,
        },
        {
          id: 'excel',
          name: this.$t('exportMenu.exportToExcel'),
          disabled: false,
          disabledHint: this.$t('analytics.feedback.exportEmployeesToExcelHint'),
          icon: 'fa fal fa-file-excel',
          isVisible: this.hasExportToExcelLicense,
        },
      ];
      return menuList.filter((value) => value.isVisible);
    },
    pdfProgressText() {
      const { done, total, running } = this.pdfArchiveStatus;
      if (!running) return '';
      return this.$t('exportMenu.pdfArchiveStatusText', { done, total });
    },
  },
});
